import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-size-chart-dialog',
  templateUrl: './size-chart-dialog.component.html',
  styleUrls: ['./size-chart-dialog.component.scss']
})
export class SizeChartDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SizeChartDialogComponent>
  ) {}

  ngOnInit(): void {
  }

  btnClose_click(){
    this.dialogRef.close();
  }

}

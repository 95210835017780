<div class="has-float-label">
    <input
      *ngIf="pattern!=='alpha'"
      [ngClass]="{'error':control.invalid && (control.dirty || control.touched) && control.errors,
      'touched': (control.dirty || control.touched) ,'long-text': longText}"
      [formControl]="control"
      [placeholder]="fieldName"
      [type]="type"
      [id]="id"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [name]="fieldName"
      >
  <input
    *ngIf="pattern=='alpha'"
    [ngClass]="{'error':control.invalid && (control.dirty || control.touched) && control.errors,
    'touched': (control.dirty || control.touched) ,'long-text': longText}"
    [formControl]="control"
    [placeholder]="fieldName"
    [type]="type"
    [id]="id"
    [readonly]="readonly"
    [maxlength]="maxlength"
    [name]="fieldName"
    onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
  >
    <label [for]="id" ><span class="required-indicator" *ngIf="hasRequredField()"> * </span>{{fieldName | translate}}</label>
    <div *ngIf="control.invalid && (control.dirty || control.touched)" style="display: flex;justify-content: flex-end;">
            <span  *ngIf="control.errors.required" class="error" >{{'validation.error-required' | translate}}</span>
            <span  *ngIf="control.errors.email" class="error" >{{'validation.error-email' | translate}}</span>
            <span  *ngIf="control.errors.pattern && type=='email'"  class="error" >{{'validation.error-email' | translate}}</span>
            <span  *ngIf="control.errors.pattern && tel" class="error" >{{'validation.error-phone' | translate}}</span>
            <span  *ngIf="control.errors.pattern && type=='text' && control.errors.pattern.requiredPattern == '^[0-9a-zA-Z]{6,9}$'" class="error" >{{'validation.error-pattern' | translate}}</span>
            <span  *ngIf="control.errors.pattern && hkId" class="error" >{{'validation.error-invaild-id' | translate}}</span>
            <span  *ngIf="control.hasError('notMatchPassword')" class="error">{{'validation.error-password' | translate}}</span>
            <span  *ngIf="control.hasError('passwordFormatError')" class="error multi-line">
              <div *ngIf="control.hasError('caseError')">{{'validation.error-password-format-1' | translate}}</div>
              <div *ngIf="control.hasError('numberSymbolError')">{{'validation.error-password-format-2' | translate}}</div>
              <div *ngIf="control.hasError('lengthError')">{{'validation.error-password-format-3' | translate}}</div>
            </span>
    </div>
</div>

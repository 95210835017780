export const ProductSizePriority = [
  {name: 'XXS', value: 0},
  {name: 'XS', value: 1},
  {name: 'XS/S', value: 2},
  {name: 'S', value: 3},
  {name: 'M', value: 4},
  {name: 'M/L', value: 5},
  {name: 'L', value: 6},
  {name: 'XL', value: 7},
  {name: 'XXL', value: 8},
  {name: 'US4.5', value: 9},
  {name: 'US5', value: 10},
  {name: 'US5.5', value: 11},
  {name: 'US6', value: 12},
  {name: 'US6.5', value: 13},
  {name: 'US7', value: 14},
  {name: 'US7.5', value: 15},
  {name: 'US8', value: 16},
  {name: 'US8.5', value: 17},
  {name: 'US9', value: 18},
  {name: 'US9.5', value: 19},
  {name: 'US10', value: 20},
  {name: 'US10.5', value: 21},
  {name: 'US11', value: 22},
  {name: 'US11.5', value: 23},
  {name: 'US12', value: 24},
];


export const ProductSizeNumberPriority = [
  {name: '00P', value: 0},
  {name: '00', value: 1},
  {name: '0', value: 2},
  {name: '2', value: 3},
  {name: '4', value: 4},
  {name: '6', value: 5},
  {name: '8', value: 6},
  {name: '10', value: 7},
  {name: '12', value: 8},
  {name: '14', value: 8}
]

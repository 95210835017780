import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown1-label',
  templateUrl: './dropdown1-label.component.html',
  styleUrls: ['./dropdown1-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Dropdown1LabelComponent implements OnInit {

  @Input() fieldName: string;
  @Input() options : any[];
  @Input() value : string;
  @Input() setDisable : boolean = false;
  @Output() valueChange = new EventEmitter<string>();

  toppings = new FormControl();

  constructor() {}

  ngOnInit(): void {
    this.value = this.value || this.options[0]?.locationCode as string || '';

  }

  onChange($event){
    this.valueChange.emit(this.value);
  }


}

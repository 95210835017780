import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-order-items',
  templateUrl: './sales-order-items.component.html',
  styleUrls: ['./sales-order-items.component.scss']
})
export class SalesOrderItemsComponent implements OnInit {

  @Input() salesOrderItems: any;

  constructor() { }

  ngOnInit(): void {
  }
}

<div id="container-wrap">
  <h3>
    YOUR INFORMATION
    <div class="dialog-required"><span class="required-indicator">* Mandatory information</span></div>
  </h3>

  <div class="form-row">
    <app-dropdown1-label [fieldName]="'Pickup location'" [options]="getLocationOptions()" [(value)]="selectedLocation">
    </app-dropdown1-label>
  </div>
  <!--  <div class="form-row">-->
  <!--    <app-dropdown1-normal [fieldName]="'Pick Up Time'" [options]="getTimeSlotOptions()" [(value)]="selectedTimeSlot">-->
  <!--    </app-dropdown1-normal>-->
  <!--  </div>-->
  <div class="flexcontainer">
    <div class="form-row">
      <app-dropdown1-normal [fieldName]="'Title'" [options]="title" [(value)]="selectedTitle">
      </app-dropdown1-normal>
    </div>
    <div class="form-row">
      <app-dropdown1-normal [fieldName]="'Birthday month'" [options]="month" [(value)]="selectedMonth">
      </app-dropdown1-normal>
    </div>
    <div class="form-row">
      <app-input [id]="'firstname'" [type]="'text'" [fieldName]="'First name (same as HK ID card)'" [form]="preOrderForm" [control]="preOrderForm.get('firstname')" [pattern]="'alpha'" [longText]="true"></app-input>
    </div>
    <div class="form-row">
      <app-input [id]="'lastname'" [type]="'text'" [fieldName]="'Last name (same as HK ID card)'" [form]="preOrderForm" [control]="preOrderForm.get('lastname')" [pattern]="'alpha'" [longText]="true"></app-input>
    </div>
  </div>
  <div class="form-row">
    <app-input [id]="'email'" [type]="'email'" [fieldName]="'Email'" [form]="preOrderForm" [control]="preOrderForm.get('email')" [longText]="true"></app-input>
  </div>
  <div class="form-row">
    <app-dropdown1-normal [fieldName]="'Country code'" [options]="countryCode" [(value)]="selectedCountryCode">
    </app-dropdown1-normal>
  </div>
  <div class="form-row">
    <app-input [id]="'phone'" [type]="'number'" [fieldName]="'Mobile (to receive the redemption SMS)'" [form]="preOrderForm" [tel]="true" [control]="preOrderForm.get('phone')"></app-input>
  </div>
  <div class="error-message" *ngIf="errorMessage!=''">{{errorMessage}}</div>
  <div class="error-message" *ngIf="successMessage!=''">{{successMessage}}</div>
  <div fxLayout="row" fxLayoutGap=15px>
    <mat-checkbox [(ngModel)]="joinICard" name="joinICard"></mat-checkbox>
    <span class="checkBox-text">
      <span>I have read and agreed to the <a class="underline" href="https://www.imaginex.com/en/terms-of-service" target="_blank">Terms and Conditions</a>,
         as well as the <a class="underline" href="https://www.imaginex.com/en/privacy-policy" target="_blank">Privacy Policy</a>, 
         and consent to transfer my contact details, including full name, phone number, email and address, to create and manage my JOYCE Beauty membership. 
         I also agree to receive the promotional and advertising materials from JOYCE Beauty in the future.​
      </span>
    </span>
  </div>
  <form [formGroup]="aFormGroup">
    <!--    (reset)="handleReset()"-->
    <!--    (expire)="handleExpire()"-->
    <!--    (load)="handleLoad()"-->
    <!--    (success)="handleSuccess($event)"-->
    <div class="tAndc">
      <div class="ck-content" [innerHTML]="tAndchtml"></div>
    </div>
    <ngx-recaptcha2 #captchaElem [siteKey]="environment.captchaSiteKey" (success)="handleSuccess($event)" (reset)="handleReset()" (expire)="handleExpire()" (error)="handleError()" [useGlobalDomain]="false" [size]="'normal'" [hl]="'en'" [theme]="'light'" [type]="'image'" formControlName="recaptcha">
    </ngx-recaptcha2>
  </form>
  <div class="button-row">
    <app-custom-button-disable [setDisable]="isFormInValid()" (click)="sendPreOrderForm()">Submit
    </app-custom-button-disable>
  </div>
</div>
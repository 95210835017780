import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { SessionStoreService } from '../../shared/services/session-store.service';
import { BehaviorSubject, Observable } from 'rxjs';

const GET_ORDER = '/order';
const CANCEL_ORDER = '/order/cancel/';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  ORDER = 'ORDER';

  order: any;
  public orderBS: BehaviorSubject<any>;
  public order$: Observable<any>;
  id: any;

  constructor(
    private httpService: HttpService,
    private ss: SessionStoreService
  ) {
    console.log('OrderService::constructor');
    this.order = this.ss.getItem(this.ORDER) ? this.ss.getItem(this.ORDER) : {};
    this.orderBS = new BehaviorSubject<any>(this.order);
    this.order$ = this.orderBS.asObservable();
  }

  setOrder(order: any) {
    this.ss.setItem(this.ORDER, order);
    this.orderBS.next(order);
  }

  clearOrder() {
    this.ss.removeitem(this.ORDER);
  }

  cancelOrder(id): Observable<any> {
    return this.httpService.post(CANCEL_ORDER + id, {});
  }

  getOrderById(id: string): Observable<any> {
    return this.httpService.get(GET_ORDER + '/' + id, null);
  }

  payOrderByIdAndTransitionId(
    id: string,
    transitionId: string
  ): Observable<any> {
    return this.httpService.post('/order/pay/' + id + '/' + transitionId, {});
  }

  getOrderByTrackingCode(orderId: any, trackingCode: any): Observable<any> {
    return this.httpService.get(
      '/order/trackingCode/' + orderId + '/' + trackingCode,
      null
    );
  }

  processPayPalPayment(orderId: any, payPalDetail: any): Observable<any> {
    const data = {
      paymentId: payPalDetail.paymentId,
      payerId: payPalDetail.PayerID,
      orderId: orderId,
    };
    return this.httpService.post('/payment/paypal/process', { body: data });
  }

  confirmDelivered(orderId: any): Observable<any> {
    return this.httpService.post('/order/dispatch/' + orderId, {});
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotFoundPageComponent} from './views/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'membership'
  },
  {
    path: '',
    children: [
      {
        path: 'campaign',
        loadChildren: () =>
          import('./views/campaign/campaign.module').then((m) => m.CampaignModule),
        data: { title: 'Campaign', breadcrumb: 'Campaign' },
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./views/payment/payment.module').then((m) => m.PaymentModule),
        data: { title: 'Payment', breadcrumb: 'Payment' },
      },
      {
        path: 'winner',
        loadChildren: () =>
          import('./views/payment/payment.module').then((m) => m.PaymentModule),
        data: { title: 'Winner', breadcrumb: 'Winner' },
      },
      {
        path: 'booking',
        loadChildren: () =>
          import('./views/booking/booking.module').then((m) => m.BookingModule),
        data: { title: 'Booking', breadcrumb: 'Booking' },
      },
      {
        path: 'membership',
        loadChildren: () =>
          import('./views/membership/membership.module').then((m) => m.MembershipModule),
        data: { title: 'Membership', breadcrumb: 'Membership' },
      },
    ],
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    data: { title: 'Not Found', breadcrumb: 'Not Found' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';

@Component({
    selector: 'app-custom-button-disable',
    templateUrl: './custom-button-disable.component.html',
    styleUrls: ['./custom-button-disable.component.scss']
})
export class CustomButtonDisableComponent implements OnInit {

    @Input() setDisable : boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

    @Output() clickToAction=new EventEmitter();

    toggle() {
        this.clickToAction.emit(null);
    }
}

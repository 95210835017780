import { Injectable } from '@angular/core';
import { SessionStoreService } from './session-store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import 'rxjs/add/observable/of';
import { BehaviorSubject, throwError, Observable } from 'rxjs';

import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  USER = 'USER';
  JWT_TOKEN = 'JWT_TOKEN';
  INVITATION_CODE = 'INVITATION_CODE';
  REFERRAL_STAFF_CODE = 'REFERRAL_STAFF_CODE';

  token;
  isAuthenticated: Boolean;
  referralStaffCode: string = null;
  user: User = null;
  user$ = (new BehaviorSubject<User>(this.user));
  signingIn: Boolean;
  return: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ss: SessionStoreService
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params.return || '/');
  }

  public signOut() {
    console.log('signOut::start');
    this.setUserAndToken(null, null, null);
    this.ss.clear();
    this.router.navigateByUrl('auth');
  }

  isLoggedIn(): Boolean {
    return this.getJwtToken() != null ? true : false;
  }

  getJwtToken() {
    return this.ss.getItem(this.JWT_TOKEN);
  }


  getUser(): Observable<User> {
    return Observable.of(this.ss.getItem(this.USER));
  }
  getUserFirstname(): Observable<string>{
    return Observable.of(this.ss.getItem(this.USER).firstname);
  }

  setUserAndToken(token: string, user: User, isAuthenticated: Boolean) {
    this.token = token;
    this.isAuthenticated = isAuthenticated;
    this.user$.next(user);
    this.ss.setItem(this.JWT_TOKEN, token);
    this.ss.setItem(this.USER, user);
  }

  setUser(user: User) {
    this.user$.next(user);
    this.ss.setItem(this.USER, user);
  }

  setToken(token: string, isAuthenticated: Boolean) {
    this.token = token;
    this.isAuthenticated = isAuthenticated;
    this.ss.setItem(this.JWT_TOKEN, token);
  }
}

<ngx-slick-carousel class="carousel"
                    #slickModal="slick-carousel"
                    [config]="productSlider1Config"
                    style="max-height: 400px;"
                    >
  <div ngxSlickItem *ngFor="let image of images" style="width: 100%;
            height: 400px;
            max-height: 100%;">
    <div style="width: 100%;
            height: 400px;
            max-height: 100%;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat"
         [ngStyle]="{backgroundImage: 'url('+ image.path +')'}"></div>

  </div>
</ngx-slick-carousel>

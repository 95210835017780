import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dropdown1-normal',
  templateUrl: './dropdown1-normal.component.html',
  styleUrls: ['./dropdown1-normal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Dropdown1NormalComponent implements OnInit {

  @Input() fieldName: string;
  @Input() options : any[];
  @Input() value : any;
  @Input() setDisable : boolean = false;
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Output() valueChange = new EventEmitter<string>();
  @Output() onChangeEvent:EventEmitter<any> = new EventEmitter()
  toppings = new FormControl();

  constructor() {}

  ngOnInit(): void {
    this.value = this.value || this.options[0]?.locationCode as string || '';

  }

  onChange($event){
    this.valueChange.emit(this.value);
    this.onChangeEvent.emit();
  }

  hasRequredField() : boolean{
    if(this.control?.validator){
      const validator = this.control.validator({} as AbstractControl);
      if(validator && validator.required){
        return true;
      }
    }
    return false;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CampaignToDateStringPipe} from './campaign-to-date-string.pipe';
import {GetValueByAttributePipe} from './get-value-by-attribute.pipe';


const pipes = [CampaignToDateStringPipe,
  GetValueByAttributePipe];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule { }

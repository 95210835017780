import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CampaignService} from "../campaign/campaign.service";

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotFoundPageComponent implements OnInit{

  campaign: any;


  constructor(private route: ActivatedRoute, private campaignService: CampaignService, private router: Router) {
  }

  ngOnInit(): void {
    this.campaignService.findActiveCampaign().subscribe(res => {
      console.log(res);
      this.campaign = res;
      this.router.navigateByUrl('campaign/' + this.campaign.id);
    });
  }

}

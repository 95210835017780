export const config = {
  localApiUrl: 'https://jby-api-prod.imaginex.com/api',
  localDomain: 'https://www.joycebeauty.com',
  domain: 'https://www.joycebeauty.com',
  apiUrl: 'https://jby-api-prod.imaginex.com/api',
  merchantId: 'jby',
  captchaSiteKey: '6Lfx-vYpAAAAAELEqiBwkuoZ-5K8fKTaPJLuKBt5',
  ga: '',
  paypalClientId: 'ATVKhJ2QwFgkNF5N631KLsSQNM-6KC1ojngaETw3Sw-IYnZRQ6enSO-QpOcsHK_PFCPWDhyfRdajiFb-'
}

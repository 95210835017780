import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-check-out-box',
  templateUrl: './check-out-box.component.html',
  styleUrls: ['./check-out-box.component.scss']
})
export class CheckOutBoxComponent implements OnInit {


  @Input() applied = false;
  @Input() onPaymentPage = false;
  @Input() salesOrder: any;
  @Output() apply = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}


<div class="size-chart-dialog-container">
  <h2 mat-dialog-title>Size Chart</h2>
  <div mat-dialog-content class="mat-typography">
    <div class="dialog-content-container">
      <div class="image-container">
        <img [src]="'../assets/images/size chart.PNG'">
        <br />
      </div>
    </div>
    <button mat-flat-button class="form-row-button-close size-close-button" (click)="btnClose_click()" tabindex="-1">CLOSE</button>
  </div>
</div>

<div class="check-out-container">
<!--    <div class="check-out-box">-->
<!--        <div fxLayout="row" fxLayoutAlign="space-between center" style="">-->
<!--            <label>Total Retail</label>-->
<!--            <label>{{salesOrder.subTotal | currency:'':'symbol':'1.0-0' }}</label>-->
<!--        </div>-->
<!--      <div fxLayout="row" fxLayoutAlign="space-between center" style="padding:9px 0;">-->
<!--        <label>Discount</label>-->
<!--        <label>{{0 | currency:'':'symbol':'1.0-0' }}</label>-->
<!--      </div>-->
<!--      <div fxLayout="row" fxLayoutAlign="space-between center" style="">-->
<!--        <label>Shipping Fee</label>-->
<!--        <label>{{salesOrder.shippingFee | currency:'':'symbol':'1.0-0' }}</label>-->
<!--      </div>-->
<!--    </div>-->
    <div class="check-out-box">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-bottom: 9px;">
          <label><strong>Order Total</strong></label>
            <label><strong>HK{{salesOrder.grandTotal | currency:'':'symbol':'1.0-0' }}</strong></label>
        </div>
    </div>
</div>

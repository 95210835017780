import { Component, Input, OnInit } from '@angular/core';
import {ProductSku} from '@shared/models/product-sku.model';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss']
})
export class ProductCarouselComponent implements OnInit {

  @Input() productSku: ProductSku;

  images: any[] = [];

  productSlider1Config = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "autoplay" : true,
    "autoplaySpeed" : 3000,
    "arrows": true
  };

  constructor() { }

  ngOnInit(): void {
    this.getImages();
  }

  ngOnChanges(changes: any) {
    console.log(changes);
    this.getImages();
  }

  getImages() {
    console.log('getImages::start');
    this.images = [];
    if (this.productSku && this.productSku.images) {
      this.productSku.images.forEach(image => {
          this.images.push({ path: (image.domain + image.path) });
      });
      console.log(this.images);
    }
  }

}

import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse, HttpClient
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {CommonService} from '../shared/services/common.service';
import {Router} from "@angular/router";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private appService: CommonService,
    private router: Router
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");
    const tokenHeader = token ? { Authorization: `Bearer ${token}` } : {};

    const originalHeaders = {};
    req.headers
      .keys()
      .forEach((key) => (originalHeaders[key] = req.headers.get(key)));

    const headers: HttpHeaders = new HttpHeaders({
      "Content-type": "application/json",
      "Accept-Language": 'en-US',
      ...tokenHeader,
      ...originalHeaders
    });

    const newReq = req.clone({
      url: `${req.url}`,
      headers
    });

    this.appService.startLoading();
    return next.handle(newReq).pipe(
      map((e: HttpEvent<any>) => {
        console.log(e);
        if (e.type != 0) {
          window.scroll(0, 0);
          this.appService.stopLoading();
        }
        return e;
      }),
      catchError((err: HttpErrorResponse) => {

        // TODO: pass error msg to show in messageDialog
        // const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        // dialogConfig.autoFocus = true;
        window.scroll(0, 0)
        this.appService.stopLoading();
        // this.dialog.open(MessageDialogComponent, dialogConfig);
        var errStatus = err.status;
        var errBody = err.error;
        console.error(`Backend returned code ${errStatus}`);
        console.log(errBody);
        console.log(errStatus);
        if (errStatus == 400) {
          // alert(errBody?.error);
        }
        else if (errStatus == 404) {
          this.router.navigateByUrl('Home');
        }
        else{
          alert("Error occured, please try later");
        }

        return throwError(err);
      })
    );
  }
}

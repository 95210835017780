import { Component, Input, OnInit } from '@angular/core';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { PaymentService } from '../../../views/payment/payment.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-paypal-button',
  templateUrl: './paypal-button.component.html',
  styleUrls: ['./paypal-button.component.scss'],
})
export class PaypalButtonComponent implements OnInit {
  @Input() order: any;

  public payPalConfig?: IPayPalConfig;

  isHover = false;

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'HKD',
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'HKD',
                value: this.order.grandTotal,
                breakdown: {
                  item_total: {
                    currency_code: 'HKD',
                    value: this.order.grandTotal,
                  },
                },
              },
              invoice_id: this.order.orderNo,
              items: [
                {
                  name: 'booking-deposit',
                  quantity: '1',
                  category: 'PHYSICAL_GOODS',
                  unit_amount: {
                    currency_code: 'HKD',
                    value: this.order.salesOrderItems[0].grandTotal,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        color: 'blue',
        layout: 'horizontal',
        shape: 'pill',
        height: 55,
        tagline: false,
      },
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((details) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
        });
      },
      onClientAuthorization: (data: any) => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
        this.paymentService
          .payOrderByIdAndTransitionId(
            this.order.id,
            data.purchase_units[0].payments.captures[0].id
          )
          .subscribe((res) => {
            this.router.navigateByUrl('payment/success');
          });
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: (err) => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        this.paymentService.getOrderById(this.order.id).subscribe((res) => {
          if (res.status !== 'CREATED') {
            if (res.status === 'PAID') {
              this.router.navigateByUrl('payment/success');
            } else if (res.status === 'DELIVERED') {
              this.router.navigateByUrl('winner/pick-up-success');
            } else {
              this.router.navigateByUrl('payment/expired/' + res.id);
            }
          }
        });
        console.log('onClick', data, actions);
      },
    };
  }

  hover(state: boolean) {
    this.isHover = state;
  }

  constructor(private router: Router, private paymentService: PaymentService) {}

  ngOnInit(): void {
    if (this.order != null) {
      this.initConfig();
    }
  }
}

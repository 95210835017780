import { Pipe, PipeTransform } from "@angular/core";



@Pipe({ name: 'dateToString' })
export class CampaignToDateStringPipe implements PipeTransform {
  month = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec'];
  transform(text: string): string {
    if (text) {
      const date = text.split('T');
      const month = date[0].split('-')[1];
      const day = date[0].split('-')[2];
      const monthNum = parseInt(month) - 1;
      return this.month[monthNum] + ' ' + day + ' ' + date[1].substring(0, 8);
    }
    return '';
  }
}

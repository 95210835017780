import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service'

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit {

  show = true

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.showLoadingPage.subscribe((show) => {
        this.show = show;
    });
  }

}

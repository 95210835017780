import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, AbstractControl } from "@angular/forms";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent implements OnInit {
  @Input() fieldName: string;
  @Input() type: string;
  @Input() id: string;
  @Input() readonly: boolean = false;
  @Input() maxlength: number;
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Input() pattern: string;
  @Input() longText: boolean = false;
  @Input() hkId: boolean = false;
  @Input() tel: boolean = false;

  constructor() {}

  ngOnInit(): void {
    //console.log(this.control);
  }
  hasRequredField() : boolean{
    if(this.control.validator){
      const validator = this.control.validator({} as AbstractControl);
      if(validator && validator.required){
        return true;
      }
    }
    return false;
  }
}

<ng-container *ngFor="let product of salesOrderItems">
    <div class="summary-product" fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutAlign="center" fxLayoutAlign.gt-xs="space-between">
        <div  class="image-container"
             [ngStyle]="{backgroundImage: 'url('+ product?.productSku?.images[0]?.domain+product?.productSku?.images[0]?.path +')'}"></div>

        <div class='item-detail' >
            <div class="item-name">
                <span>{{product.productSku.productSkuEavs | getValueByAttribute:'name':'value'}} {{product.productSku.productSkuEavs | getValueByAttribute:'description':'value'}}</span>
            </div>
            <div class="item-attribute">SKU:
                <span>{{product.productSku.sku}}</span>
            </div>
            <div class="item-attribute">Qty: <span>{{product.quantity}}</span></div>
            <div class='item-price'>HK{{product.subTotal / product.quantity | currency:'':'symbol':'1.0-0'}}</div>
        </div>
    </div>
</ng-container>

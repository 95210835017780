import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CampaignService } from '../../../views/campaign/campaign.service';
import { environment } from '@env/environment';
import { ReCaptcha2Component } from 'ngx-captcha';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pre-order-form',
  templateUrl: './pre-order-form.component.html',
  styleUrls: ['./pre-order-form.component.scss'],
})
export class PreOrderFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  locations: any[] = [];
  selectedProductSku: any;
  @Input() set selectedFirstProductSku(value: any) {
    this.selectedProductSku = value;
    this.changeProductSku();
  }
  get selectedFirstProductSku() {
    return this.selectedProductSku;
  }
  @Input() campaignId: any;
  @Input() tAndchtml: any;
  // timeSlots: any[] = [];
  // selectedTimeSlot: any = {};
  environment = environment;
  private subscriptions: Subscription;

  title = [
    {
      key: 'Mr',
      value: 'Mr.',
    },
    {
      key: 'Ms',
      value: 'Ms.',
    },
    {
      key: 'Miss',
      value: 'Ms.',
    }
  ];

  month = [
    {
      key: 'January',
      value: '1',
    },
    {
      key: 'February',
      value: '2',
    },
    {
      key: 'March',
      value: '3',
    },
    {
      key: 'April',
      value: '4',
    },
    {
      key: 'May',
      value: '5',
    },
    {
      key: 'June',
      value: '6',
    },
    {
      key: 'July',
      value: '7',
    },
    {
      key: 'August',
      value: '8',
    },
    {
      key: 'September',
      value: '9',
    },
    {
      key: 'October',
      value: '10',
    },
    {
      key: 'November',
      value: '11',
    },
    {
      key: 'December',
      value: '12',
    },
  ];

  countryCode = [
    {
      key: '+852',
      value: '852',
    },
    {
      key: '+853',
      value: '853',
    },
    {
      key: '+86',
      value: '86',
    }
  ];

  selectedLocation: any;
  selectedMonth: string = '1';
  selectedTitle = 'Mr.';
  selectedCountryCode = '852';
  errorMessage = '';
  successMessage = '';
  formLock = false;
  aFormGroup: FormGroup;
  reCaptcha = false;

  joinICard: any = true;
  optIn: any = false;

  getLocationOptions() {
    const options = [];
    this.locations.forEach((location) => {
      options.push(location);
    });
    console.log(options);
    
    return options;
  }

  // getTimeSlotOptions(){
  //   const options = [];
  //   this.timeSlots.forEach( timeSlot => {
  //     options.push(timeSlot);
  //   });
  //   return options;
  // }

  submitSuccess = false;

  preOrderForm = new FormGroup({
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{8}$'),
    ]),
  });

  constructor(
    private matSnackBar: MatSnackBar,
    private campaignService: CampaignService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
    });
    if (
      this.selectedFirstProductSku != null &&
      this.selectedFirstProductSku?.locationDetail?.length > 0
    ) {
      this.locations = [];
      this.selectedFirstProductSku.locationDetail.forEach((location) => {
        this.locations.push(location.location);
      });
      this.selectedLocation = this.locations[0];
      // this.timeSlots =  this.selectedLocation.pickUpDetail;
      // this.selectedTimeSlot = this.selectedLocation.pickUpDetail[0];
    }
  }

  ngAfterViewInit(): void {}

  changeProductSku(): void {
    this.locations = [];
    if (
      this.selectedProductSku != null &&
      this.selectedProductSku?.locationDetail?.length > 0
    ) {
      console.log(this.selectedProductSku);
      this.selectedProductSku.locationDetail.forEach((location) => {
        this.locations.push(location.location);
      });
      this.selectedLocation = this.locations[0];
      // this.timeSlots =  this.selectedLocation.pickUpDetail;
      // this.selectedTimeSlot = this.selectedLocation.pickUpDetail[0];
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  sendPreOrderForm(): void {
    if (!this.isFormInValid()) {
      this.errorMessage = '';
      const preOrderRequest: any = {};
      preOrderRequest.firstname = this.preOrderForm.get('firstname').value;
      preOrderRequest.lastname = this.preOrderForm.get('lastname').value;
      preOrderRequest.email = this.preOrderForm.get('email').value;
      preOrderRequest.countryCode = this.selectedCountryCode;
      preOrderRequest.phone = this.preOrderForm.get('phone').value;
      preOrderRequest.hkId = '';
      preOrderRequest.targetProductSku = this.selectedFirstProductSku.sku;
      preOrderRequest.campaignId = this.campaignId;
      preOrderRequest.locationCode = this.selectedLocation.locationCode;
      preOrderRequest.title = this.selectedTitle;
      preOrderRequest.birthdayMonth = this.selectedMonth;
      preOrderRequest.joinICard = this.joinICard;
      preOrderRequest.optIn = this.optIn;
      // preOrderRequest.pickUpDate = this.selectedTimeSlot.pickUpDate;
      // preOrderRequest.pickUpTime = this.selectedTimeSlot.pickUpTime;
      this.formLock = true;
      this.campaignService.submitPreOrder(preOrderRequest).subscribe(
        (res) => {
          console.log(res);
          this.successMessage = 'PreOrder Submitted successful';
          this.goToSuccessPage();
          this.formLock = true;
        },
        (error) => {
          console.log(error);
          this.formLock = false;
          this.errorMessage = error.error.error;
        }
      );
    } else {
      this.matSnackBar.open('Please complete the form', null, {
        duration: 2000,
      });
    }
  }

  isFormInValid(): boolean {
    if (!this.reCaptcha) {
      return true;
    }

    if (this.formLock) {
      return true;
    }
    return this.preOrderForm.invalid || !this.selectedFirstProductSku;
  }

  handleSuccess(event) {
    this.reCaptcha = true;
    this.cdr.detectChanges();
  }
  handleReset() {
    this.reCaptcha = false;
    this.cdr.detectChanges();
  }
  handleExpire() {
    console.log('Expire');
    this.captchaElem.resetCaptcha();
    this.reCaptcha = false;
    this.cdr.detectChanges();
  }
  handleError() {
    console.log('Error');
    this.captchaElem.resetCaptcha();
    this.reCaptcha = false;
    this.cdr.detectChanges();
  }

  goToSuccessPage() {
    this.router.navigateByUrl('campaign/preorder-success/' + this.campaignId);
  }
}

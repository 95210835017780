import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {HttpService} from '@shared/services/http.service';
import {ProductSizeNumberPriority, ProductSizePriority} from "../../shared/models/product-size-priority";

const GET_CAMPAIGN = '/campaign/get';
const GET_ACTIVE_CAMPAIGN = '/campaign/active-campaign';
const POST_PREORDER = '/campaign/preOrder/create';
const GET_LOCATIONBYLOCATIONCODE = '/location/locationCode';
const GET_PRC_REGISTRATION_LINK = '/campaign/prc-registration-link';
const SUBMIT_BOOKING = '/campaign/booking/submit';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private httpService: HttpService) {
  }

  findActiveCampaign(): Observable<any> {
    return this.httpService.get(GET_ACTIVE_CAMPAIGN, null);
  }
  
  getPrcRegistrationLink(): Observable<any> {
    return this.httpService.get(GET_PRC_REGISTRATION_LINK, null);
  }

  getCampaignById(campaignId: string): Observable<any> {
    return this.httpService.get(GET_CAMPAIGN + '/' + campaignId, null);
  }

  submitPreOrder(preOrderRequest: any): Observable<any> {
    return this.httpService.post(POST_PREORDER, {body: preOrderRequest});
  }

  getColorPerProduct(product: any): string[] {
    const productColorArr: string[] = [];

    product.productSkus.forEach(item => {
      // let productName = item.productSkuEavs[0].value;
      let productColor;
      let i = 0;
      while (i < item.productSkuEavs.length) {
        if (item.productSkuEavs[i].attribute === 'displayColor') {
          productColor = item.productSkuEavs[i].value;
          break;
        }
        i++;
      }
      productColorArr.push(productColor);
    });
    return productColorArr;
  }


  getSizePerProduct(productSize): string[] {
    let sizes: string[] = [];

    productSize.productSkus.forEach(item => {
      let productSize;
      let i = 0;
      while (i < item.productSkuEavs.length) {
        if (item.productSkuEavs[i].attribute === 'displaySize') {
          productSize = item.productSkuEavs[i].value;
          break;
        }
        i++;
      }
      if (!sizes.includes(productSize)) {
        sizes.push(productSize);
      }
    });

    sizes = sizes.sort((n1, n2) => {
      const n1Temp = n1.replace(/ /g, '');
      const n2Temp = n2.replace(/ /g, '');

      const n1Value = ProductSizePriority.filter(e => e.name == n1Temp)[0] ? ProductSizePriority.filter(e => e.name == n1Temp)[0].value : 99;
      const n2Value = ProductSizePriority.filter(e => e.name == n2Temp)[0] ? ProductSizePriority.filter(e => e.name == n2Temp)[0].value : 100;
      if (n1Value > n2Value) {
        return 1;
      }
      if (n1Value < n2Value) {
        return -1;
      }
      return 0;
    });

    if (ProductSizeNumberPriority.find(e => sizes.includes(e.name))) {
      sizes = sizes.sort((n1, n2) => {
        const n1Value = ProductSizeNumberPriority.filter(e => e.name == n1)[0] ? ProductSizeNumberPriority.filter(e => e.name == n1)[0].value : 99;
        const n2Value = ProductSizeNumberPriority.filter(e => e.name == n2)[0] ? ProductSizeNumberPriority.filter(e => e.name == n2)[0].value : 100;
        if (n1Value > n2Value) {
          return 1;
        }
        if (n1Value < n2Value) {
          return -1;
        }
        return 0;
      })
    }
    // else {
    //   sizes = sizes.sort((n1, n2) => {
    //
    //     if (n1 > n2) {
    //       return 1;
    //     }
    //     if (n1 < n2) {
    //       return -1;
    //     }
    //     return 0;
    //   });
    // }
    return sizes;
  }

  getInseamPerProduct(productInseam): string[] {
    let inseams: string[] = [];

    productInseam.productSkus.forEach(item => {
      let productInseam;
      let i = 0;
      while (i < item.productSkuEavs.length) {
        if (item.productSkuEavs[i].attribute === 'inseam') {
          productInseam = item.productSkuEavs[i].value;
          break;
        }
        i++;
      }
      inseams.push(productInseam);
    });
    inseams = inseams.sort((n1, n2) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    });
    return inseams;
  }

  getWaistPerProduct(productWaist): string[] {
    let waists: string[] = [];

    productWaist.productSkus.forEach(item => {
      let productWaist;
      let i = 0;
      while (i < item.productSkuEavs.length) {
        if (item.productSkuEavs[i].attribute === 'waist') {
          productWaist = item.productSkuEavs[i].value;
          waists.push(productWaist);
          break;
        }
        i++;
      }
    });
    waists = waists.sort((n1, n2) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    });
    return waists;
  }

  getLocationbyLocationCode(locationCode): Observable<any>{
    return this.httpService.get(GET_LOCATIONBYLOCATIONCODE + '/' + locationCode, null);
  }

  submitBooking(bookingRequest: any): Observable<any> {
    return this.httpService.post(SUBMIT_BOOKING, {body: bookingRequest});
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutModule } from '@angular/cdk/layout';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// LIBRARY
import { FlexLayoutModule } from '@angular/flex-layout';

// MATERIAL-UI
import { MaterialModule } from '../../material.module';

import { TranslateModule } from '@ngx-translate/core';

// SERVICES
import { HttpService } from './services/http.service';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { SharedPipesModule } from './pipes/shared-pipe.module';
import { ProductCarouselComponent } from './components/product-carousel/product-carousel.component';
import { SizeChartDialogComponent } from './components/size-chart-dialog/size-chart-dialog.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { PreOrderFormComponent } from "@shared/components/pre-order-form/pre-order-form.component";
import { InputComponent } from "@shared/components/input/input.component";
import { Dropdown1LabelComponent } from "@shared/components/dropdown1-label/dropdown1-label.component";
import { CustomButtonDisableComponent } from "@shared/components/custom-button-disable/custom-button-disable.component";
import { SharedMaterialModule } from "@shared/shared-material.module";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SalesOrderItemsComponent } from "@shared/components/sales-order-items/sales-order-items.component";
import { CheckOutBoxComponent } from "@shared/components/check-out-box/check-out-box.component";
import { PaypalButtonComponent } from "@shared/components/paypal-button/paypal-button.component";
import { NgxPayPalModule } from "ngx-paypal";
import { Dropdown1NormalComponent } from "@shared/components/dropdown-normal/dropdown1-normal.component";
// COMPONENT

const classesToInclude = [
  LoadingPageComponent,
  ProductCarouselComponent,
  SizeChartDialogComponent,
  PreOrderFormComponent,
  InputComponent,
  Dropdown1LabelComponent,
  CustomButtonDisableComponent,
  SalesOrderItemsComponent,
  CheckOutBoxComponent,
  PaypalButtonComponent,
  Dropdown1NormalComponent
];

const modulesToImportExport = [
  MaterialModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  SharedPipesModule,
  TranslateModule
];

const serviceToProvide = [HttpService];

@NgModule({
  declarations: classesToInclude,
  imports: [CommonModule, LayoutModule, modulesToImportExport, IvyCarouselModule, SharedMaterialModule, SlickCarouselModule, NgxPayPalModule],
  providers: [serviceToProvide],
  exports: [modulesToImportExport, classesToInclude, SharedPipesModule],
})
export class SharedModule { }
